












import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CmsComponentsFaqContainer',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  setup() {
    const openFaqId = ref<number | null>(null);

    const toggleFaq = (faqId: number) => {
      if (openFaqId.value === faqId) {
        openFaqId.value = null;
      } else {
        openFaqId.value = faqId;
      }
    };

    return {
      openFaqId,
      toggleFaq
    };
  }
});
