

























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { getCustomFieldByKey } from '~/helpers/utils';

type FaqData = {
  id: number;
  sequence: number;
  description: string;
  urls?: {
    title: string;
    url: string;
    target: string;
  }[];
  custom_fields: {
    key: string;
    value_string: string;
    value_string_area: string;
  }[];
};

export default defineComponent({
  name: 'FAQ',
  props: {
    data: {
      type: Object as PropType<FaqData>,
      required: true,
      default: null
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const getCustomField = (key: string) => {
      return getCustomFieldByKey(props.data.custom_fields, key);
    };

    const question = getCustomField('title')?.value_string;
    const answer = getCustomField('answer')?.value_string_area;

    const buttonData = computed(() => {
      const url = props.data?.urls?.[0];
      if (!url) return null;
      return {
        text: url.title || '',
        url: url.url || '#',
        target: url.target || '_self'
      };
    });

    return { getCustomField, buttonData, question, answer };
  }
});
