import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=c20be102&"
import script from "./Container.vue?vue&type=script&lang=ts&"
export * from "./Container.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsComponentsFaq: require('/var/www/packages/theme/components/cms-components/faq/Faq.vue').default})
